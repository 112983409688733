var pomlo_app = {};

// Define constants
pomlo_app.MOBILE_MAX_WIDTH = 768;
pomlo_app.TABLET_MAX_WIDTH = 992;
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.SMALL_HEADER_HEIGHT = 60;
pomlo_app.LARGE_HEADER_HEIGHT = 88;

/**
 * Toggle header style depending and/on device or scroll position
 * Debounce the main function to avoid browser overhead
 */
pomlo_app.toggleHeaderStyle = function() {
    // On mobile device
    if (jQuery(window).width() < pomlo_app.TABLET_MAX_WIDTH ) {
        jQuery("#navbar").css("background-color", "#ffffff");
        return;
    }

    var opacity = jQuery("#main-content").scrollTop() / (pomlo_app.LARGE_HEADER_HEIGHT * 4);
    if (opacity > 1) opacity = 1;
    jQuery("#navbar").css("background-color", "rgba(255,255,255, " + opacity + ")");
};

pomlo_app.debouncedToggleHeaderStyle = debounce(pomlo_app.toggleHeaderStyle, 50);


/**
 * Display loader until images are loaded
 */
pomlo_app.displayLoader = function() {
    jQuery('.img-loader-wait').imagesLoaded(function() {
        jQuery('.img-bg-loader-wait').imagesLoaded( { background: true }, function() {
            var delay = document.location.host ? 0 : 500;
            setTimeout(function(){
                jQuery("#loader").fadeOut();
            }, delay);
        });
    });
};


/**
 * Function to scroll to a specific html element
 */
pomlo_app.scrollTo = function(selector) {
    var headerHeight = (jQuery(window).width() < pomlo_app.TABLET_MAX_WIDTH) ? pomlo_app.SMALL_HEADER_HEIGHT :  pomlo_app.LARGE_HEADER_HEIGHT;
    // console.log("header: " + headerHeight);
    // console.log(selector + " offset: " + jQuery(selector).offset().top);
    // console.log(selector + " position: " + jQuery(selector).position().top);
    // jQuery("#main-content").animate({
    //     scrollTop: (jQuery(selector).offset().top - headerHeight)
    // }, 600);
    //jQuery("#main-content " + selector).smoothScroll();

    // Rely on jQuery plugin as native scrollTop & offset is not reliable
    jQuery.smoothScroll({
        offset: -(headerHeight - 1),
        scrollElement: $("#main-content"),
        scrollTarget: selector
    });
};

/**
 * Function to animate the keywords section
 * Observe this function to trigger it when the section is in viewport
 */
pomlo_app.animateKeywords = function (){
    var effects = [
        "bounceInDown",
        "bounceInUp",
        "bounceInLeft",
        "bounceInRight",
    ];

    var delays = [
        "",
        "delay-1s",
        "delay-2s",
    ];

    var speeds = [
        "faster",
        "fast",
        "",
        "slow"
    ];

    jQuery(".keyword").each(function() {
        jQuery(this).addClass(speeds[Math.floor(Math.random() * effects.length)])
            .addClass(delays[Math.floor(Math.random() * delays.length)])
            .addClass(effects[Math.floor(Math.random() * speeds.length)]);
    });
};

pomlo_app.observeKeywordsSection = function() {
    var keywordsSection = document.querySelector("#home-how");

    keywordsSectionObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                pomlo_app.animateKeywords();
                observer.unobserve(entry.target);
            }
        });
    });

    if (keywordsSection) {
        keywordsSectionObserver.observe(keywordsSection);
    }
};

/**
 * Main jQuery events
 */
jQuery(document).ready(function($) {
    // Init interface
    pomlo_app.toggleHeaderStyle();
    pomlo_app.displayLoader();

    // Setup intersection observers to trigger actions
    pomlo_app.observeKeywordsSection();

    // Handling SPAM with that form value
    setTimeout(function(){
        $("#form-contact").find("fieldset").append("<input type='hidden' value='1' name='transactionKey'/>");
    }, 2000);

    // Init OWL hero slider
    $("#homeHeroSlider").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        nav: false,
        dots: false,
        animateOut: "fadeOut",
        onInitialized: function(event){
             if (event.item.count === 1) {
                 jQuery("#heroSliderPrev").hide();
                 jQuery("#heroSliderNext").hide();
             }
        }
    });

    owlHeroSlider = jQuery("#homeHeroSlider").owlCarousel();
    jQuery("#heroSliderPrev").click(function () {
        owlHeroSlider.trigger("prev.owl.carousel");
    });

    jQuery("#heroSliderNext").click(function () {
        owlHeroSlider.trigger("next.owl.carousel");
    });


    jQuery("#homeWhoSlider").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayHoverPause: true
    });

    // Menu button
    $("#btn-menu-open, #btn-menu-close").click(function() {
        $(".offcanvas").toggleClass("active");
    });

    $(".hide-menu").click(function() {
        $(".offcanvas").removeClass("active");
    });

    // Removes some anchor links managed by js click/animatescroll.
    // And manage scroll buttons
    $(".scroll-link").removeAttr("href");

    $(".scroll-to-top").click(function() {
        pomlo_app.scrollTo("#page-top");
    });

    $(".scroll-to").click(function(){
        pomlo_app.scrollTo($(this).attr("data-target"));
    });

    // Rosace hover for mobile
    $(".dot").on('touchstart', function(){
        $(this).addClass('hover_mobile');
    });

    $(".dot").on('touchend', function(){
        $(this).removeClass('hover_mobile');
    });

    // Chronology hover for mobile
    $(".mobile-hoverable").on('touchstart', function(){
        $(this).children().addClass('hover-mobile');
    });

    $(".mobile-hoverable").on('touchend', function(){
        $(this).children().removeClass('hover-mobile');
    });

    $("#form-contact").submit(function(e) {
        if(!$("#private-policy").is(":checked")){
            $("#privacy-policy-message").append("Envoi impossible, veuillez lire et accepter la politique de confidentialité");
            return false;
        }
    });
});

jQuery("#main-content").scroll(function() {
    pomlo_app.toggleHeaderStyle();
});

jQuery(window).resize(function() {
    pomlo_app.debouncedToggleHeaderStyle();
});
